<template>
  <svg>
    <defs>
      <pattern id="hash-pattern-private"
               patternUnits="userSpaceOnUse"
               :width="patterns.hash.size"
               :height="patterns.hash.size">
        <image :xlink:href="patterns.hash.private"
               x="0"
               y="0"
               :width="patterns.hash.size"
               :height="patterns.hash.size" />
      </pattern>
    </defs>
    <defs>
      <pattern id="hash-pattern-noData"
               patternUnits="userSpaceOnUse"
               :width="patterns.hash.size"
               :height="patterns.hash.size">
        <image :xlink:href="patterns.hash.noData"
               x="0"
               y="0"
               :width="patterns.hash.size"
               :height="patterns.hash.size" />
      </pattern>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'HashPatterns',
  data() {
    return {
      patterns: {
        hash: {
          private: '/assets/ui/PNG/trame-black.png',
          noData: '/assets/ui/PNG/trame.jpg',
          size: 200,
        },
      },
    };
  },
};
</script>