<template>
  <div class="floor-rotation">
    <svg class="cube"
         viewBox="0 0 100 100">
      <g v-if="displayMode === 'iso'"
         class="cube-group">
        <rect :x="cube.left.x"
              :y="cube.left.y"
              :width="cube.size"
              :height="cube.size"
              class="cube__left"
              @click="rotateToRight" />
        <rect :x="cube.front.x"
              :y="cube.front.y"
              :width="cube.size"
              :height="cube.size"
              class="cube__front"
              @click="rotateToLeft" />
        <rect :x="cube.top.x"
              :y="cube.top.y"
              :width="cube.size"
              :height="cube.size"
              class="cube__top"
              @click="toggleDisplayMode" />
        <text :x="cube.direction.x"
              :y="cube.direction.y"
              class="cube__direction" />
      </g>
      <g v-else>
        <rect :x="25"
              :y="20"
              width="45px"
              height="45px"
              class="square"
              @click="toggleDisplayMode" />
      </g>
    </svg>
    <curved-arrow class="floor-rotation__arrow floor-rotation__arrow--left"
                  alt="curved arrow to left"
                  @click.native="rotateToRight" />
    <curved-arrow class="floor-rotation__arrow floor-rotation__arrow--right"
                  alt="curved arrow to right"
                  @click.native="rotateToLeft" />
  </div>
</template>

<script>
import CurvedArrow from '@/app/components/ui/pictos/curved-arrow.vue';
import { mapState } from 'vuex';

export default {
  name: 'FloorRotation',
  components: { CurvedArrow },
  data() {
    return {
      cube: {
        size: '40%',
        left: {
          x: '10px',
          y: '40px',
        },
        front: {
          x: '50px',
          y: '40px',
        },
        top: {
          x: '64px',
          y: '-4px',
        },
        direction: {
          x: '20px',
          y: '120px',
        },
      },
    };
  },
  computed: {
    ...mapState('ui', {
      currentOrientationDegree: state => state.floors.currentOrientationDegree,
      displayMode: state => state.floors.displayMode,
    }),
    currentOrientation() {
      return this.orientations[this.currentOrientationIndex];
    },
  },
  methods: {
    rotateToLeft() {
      const newDegreeRotation = this.currentOrientationDegree - 90;
      this.$store.commit('ui/SET_CURRENT_FLOORS_ORIENTATION_DEGREE', newDegreeRotation);
    },
    rotateToRight() {
      const newDegreeRotation = this.currentOrientationDegree + 90;
      this.$store.commit('ui/SET_CURRENT_FLOORS_ORIENTATION_DEGREE', newDegreeRotation);
    },
    toggleDisplayMode() {
      this.$store.dispatch('ui/toggleDisplayMode');
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.floor-rotation
  position relative
  z-index 1000
  margin-left -7px
  .floor-rotation__arrow
    position absolute
    bottom 0px
    width 20px
    cursor pointer
    transition transform 0.3s ease
  .floor-rotation__arrow--left
    left 15px
    transform scaleX(-1)
    &:hover
      transform scaleX(-1) scale(1.3)
  .floor-rotation__arrow--right
    left 42px
    &:hover
      transform scale(1.3)
  .square
    opacity 0.6
    cursor pointer
    fill white
    &:hover
      fill $primary
  .cube
    width 80px
    height 80px
    .cube__left, .cube__front, .cube__top
      transform-origin center
      fill white
    .cube__left
      cursor pointer
      transform rotateX(30deg) rotateY(40deg)
      fill darken(white, 25%)
      &:hover
        fill $primary
    .cube__front
      cursor pointer
      transform rotateX(30deg) rotateY(-40deg)
      fill darken(white, 10%)
      &:hover
        fill $primary
    .cube__top
      cursor pointer
      transform rotateX(-65deg) rotateY(0deg) rotateZ(-45deg) scale(1.07)
      &:hover
        fill $primary
    .cube__direction
      width $cube-size
      height $cube-size
      font-size 25px
      transform rotateX(-65deg) rotateY(0deg) rotateZ(-45deg) scale(1.07)
      transform-origin center
</style>
