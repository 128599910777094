<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 17.77 8.7"
  >
    <g id="Calque_2"
       data-name="Calque 2"
    >
      <g id="Calque_1-2"
         data-name="Calque 1"
      >
        <path class="cls-1"
              d="M.5,8.2A22.06,22.06,0,0,0,17.27.5"
        />
        <polyline class="cls-1"
                  points="17.27 6.15 17.27 0.5 11.63 0.5"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CurvedArrow',
};
</script>

<style lang="stylus" scoped>

.cls-1
  fill none
  stroke #292828
  stroke-linecap round
  stroke-linejoin round
</style>
