import SpaceMixins from '@/app/mixins/building-viewer/actions/space';
import debounceMixin from '@/app/mixins/debounce';

export default {
  mixins: [SpaceMixins, debounceMixin],
  computed: {
    currentZone() {
      return this.$store.state.zones.selectedZone;
    },
  },
  methods: {
    zoneEditionMode() {
      this.$store.commit('zones/properties/selectProperty', null);
    },
    async zoneEditionClick(space, evt, keys) {
      if (space && this.currentZone && !this.drag.mousemoved) {
        if (this.$store.getters['zones/spaceIsAlreadyInZone'](space.id)) {
          const spaceZone = this.$store.getters['zones/getSpaceZone'](space.id);
          if (spaceZone.uuid === this.currentZone.uuid) {
            this.currentZone.removeSpace(space.id);
            this.debounce(async () => {
              await this.$store.dispatch('zones/update', { buildingUuid: this.currentBuilding.uuid, zone: this.currentZone });
              this.$q.notify({
                message: this.$t('pages.buildingConfiguration.parcel.parcelEdition.toasts.allParcelsSaved'),
                type: 'positive',
                position: 'bottom-left',
              });
            }, 3000);
          } else
            this.$q.notify({
              message: this.$t('pages.buildingConfiguration.parcel.parcelEdition.toasts.spaceAlreadyAssignedTo', { zone: spaceZone.name }),
              type: 'negative',
              position: 'bottom-left',
            });
        } else {
          if (this.currentZone.spaces.length > 0) {
            const firstSpaceUuid = this.currentZone.spaces[0];
            const firstSpaceFloor = this.getFloorFromSpaceId(firstSpaceUuid);
            const currentSpaceFloor = this.getFloorFromSpaceId(space.id);
            if (firstSpaceFloor.uuid !== currentSpaceFloor.uuid) {
              this.$q.notify({
                message: this.$t('pages.buildingConfiguration.parcel.parcelEdition.toasts.spaceHasToBeSameFloor'),
                type: 'negative',
                position: 'bottom-left',
              });
              return;
            }
          }
          this.currentZone.addSpace(space.id);
          if (this.currentZone.spaces.length === 1) this.currentZone.uuid = await this.$store.dispatch('zones/create', this.currentZone);
          this.debounce(async () => {
            await this.$store.dispatch('zones/update', { buildingUuid: this.currentBuilding.uuid, zone: this.currentZone });
            this.$q.notify({
              message: this.$t('pages.buildingConfiguration.parcel.parcelEdition.toasts.allParcelsSaved'),
              type: 'positive',
              position: 'bottom-left',
            });
          }, 3000);
        }
      }
      this.drag.mousemoved = false;
    },
  },
};
