<template>
  <div class="ticket-info">
    <div class="header">
      <div class="header-main">
        <h3 v-if="ticket"
            class="ticket-title">
          {{ ticket.title }}
        </h3>
        <h3 v-if="ticketId === 'tempDot'"
            class="ticket-title">
          {{$t('pages.ticket.ticketInfo.title')}}
        </h3>
      </div>
    </div>
    <!-- ticket already created -->
    <div v-if="ticket"
         class="main grid-wrapper">
      <div class="grid-tile">
        <label class="grid-tile__label">{{$t('pages.ticket.ticketInfo.date')}}</label>
        <span class="grid-tile__value">
          {{ formatDate(ticket.dateCreation) }}
        </span>
      </div>
      <div class="grid-tile">
        <label class="grid-tile__label">{{$t('pages.ticket.ticketInfo.author')}}</label>
        <span class="grid-tile__value">
          {{ ticket.author.firstName }} {{ ticket.author.lastName }}
        </span>
      </div>
      <div class="grid-tile">
        <label class="grid-tile__label">{{$t('pages.ticket.ticketInfo.state')}}</label>
        <span class="ticket-status o-chip grid-tile__value">
          {{$t(`pages.ticket.sidebarOut.ticketStatus.${ticket.status}`)}}
        </span>
      </div>
      <div class="grid-tile">
        <label class="grid-tile__label">{{$t('pages.ticket.ticketInfo.goToTicket')}}</label>
        <span class="button-container grid-tile__value"
              @click="setCurrentTicket(ticket)">
          <ChevronPicto size="20px"
                        class="open-ticket" />
        </span>
      </div>
    </div>
    <!-- new ticket, not created -->
    <div v-if="ticketId === 'tempDot'"
         class="main grid-wrapper">
      <div class="grid-tile">
        <label class="grid-tile__label">
          {{$t('pages.ticket.ticketInfo.date')}}
        </label>
        <span class="grid-tile__value">
          {{ currentDate }}
        </span>
      </div>
      <div class="grid-tile">
        <label class="grid-tile__label">
          {{$t('pages.ticket.ticketInfo.author')}}
        </label>
        <span class="grid-tile__value">
          {{ user.firstName }} {{ user.lastName }}
        </span>
      </div>
      <div class="grid-tile">
        <label class="grid-tile__label">{{$t('pages.ticket.creationEdition.scopes.room')}}</label>
        <span class="grid-tile__value">
          {{ space.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import ChevronPicto from '@/app/components/ui/pictos/chevron.vue';
import Space from '@/services/models/topology/space';

export default {
  name: 'TicketInfo',
  components: {
    ChevronPicto,
  },
  props: {
    ticketId: {
      type: String,
      default: '',
    },
    space: {
      type: Space,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    tickets() {
      return this.$store.state.tickets.collection;
    },
    ticket() {
      return this.tickets.find(t => t.shortId === this.ticketId);
    },
    currentDate() {
      return moment()
        .format('DD/MM/YYYY - HH[h]mm')
        .toString();
    },
  },
  mounted() {
    if (this.ticket) this.$emit('click', this.ticket);
  },
  updated() {
    if (this.ticket) this.$emit('click', this.ticket);
  },
  methods: {
    formatDate(date) {
      return moment(date)
        .format('DD/MM/YYYY - HH[h]mm')
        .toString();
    },
    setCurrentTicket(ticket) {
      this.$store.dispatch('tickets/readPrivate', ticket.shortId);
      this.$store.commit('tickets/setCurrentTicket', ticket.shortId);
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.ticket-info
  display flex
  flex-direction column
  min-width 200px
  max-width 500px
  .header
    padding $space-2
    .header-main
      display flex
      .ticket-title
        text-transform capitalize
        font-weight 700
        font-size $fs-h2
        line-height 1
  .grid-tile__label
    text-transform uppercase
  .main
    .ticket-status
      background-color $dark
      color white
    .button-container
      display flex
      justify-content center
      width 100%
      cursor pointer
    .open-ticket
      transform rotate(-90deg)

.grid-tile__label
  margin-bottom $space-1
</style>
