




















import { ApiDevice } from '@/types/api';
import Vue from 'vue';

export default Vue.extend({
  name: 'ListSpaceDevices',
  props: {
    spaceDevices: {
      type: Object,
      default: null
    },
  },
  data: () => ({
    actionDevices: [] as string[],
  }),
  async created() {
    if (!this.$store.state.oasisDevices.collection?.elts?.length)
      await this.$store.dispatch('oasisDevices/fetch');
    this.actionDevices = this.$store.state.oasisDevices.collection.elts
      .map((d1: ApiDevice) => d1.deviceUuid);
  },
  methods: {
    getIcon(deviceType: any) {
      const type = deviceType.name.toLowerCase();
      switch (type) {
        case String(type.match(/.*light.*/i)):
        case String(type.match(/.*bulb.*/i)):
          return 'fas fa-lightbulb';
        case String(type.match(/.*qrad.*/i)):
          return 'fas fa-thermometer-three-quarters';
        case String(type.match(/.*ecometer.*/i)):
          return 'fas fa-bolt';
      }
      return 'far fa-dot-circle';
    },
    disabledDevice(device:any) {
      return !this.actionDevices.includes(device.uuid);
    },
  },
});
