import chroma from 'chroma-js';

export default {
  computed: {
    currentWhitelistedSpaces() {
      return this.$store.getters['building/currentWhitelistedSpaces'];
    },
  },
  methods: {
    spaceMouseenter(space, evt, keys) {
      if (this.viewerMode !== 'navigation') return;
      if (space.style.fill === 'white' || space.style.fill.includes('url')) return;
      this.currentHoveredSpaceColor = space.style.fill;
      space.style.fill = chroma(this.rgbToArray(space.style.fill)).brighten(0.4);
    },
    spaceMouseout(space, evt, keys) {
      if (this.viewerMode !== 'navigation') return;
      if (space.style.fill === 'white' || space.style.fill.includes('url')) return;
      space.style.fill = this.currentHoveredSpaceColor;
      this.currentHoveredSpaceColor = null;
      this.colorSpacesWithData(this.currentTimelineCursorDate);
    },
    isRGBColor: color => color.substring(0, 2) === 'rgb',
    rgbToArray(rgbString) {
      if (!this.isRGBColor(rgbString)) return rgbString;
      rgbString = rgbString.split('(')[1].split(',');
      rgbString[2] = rgbString[2].substring(0, rgbString[2].length - 1);
      return rgbString;
    },
    updateSpaceSelectionStyle() {
      const curSelection = this.filteredSelection;
      this.resetSpacesStyle();
      if (['simple'].includes(this.viewerMode)) return;
      if (!(this.currentBuilding.uuid in curSelection)) return;
      const buildingUuid = this.currentBuilding.uuid;
      for (const floorUuid in curSelection[buildingUuid]) {
        const floor = this.currentBuilding.next.find(f => f.uuid === floorUuid);
        if (curSelection[buildingUuid][floorUuid].length === 0) {
          floor.next.forEach(s => {
            const find = floor.whitelist.find(space => space === s.uuid);
            if (!find) return;
            const space = this.$palmier.getSpace(s.idIFC);
            if (!space) return;
            if (
              this.currentOapp &&
              this.currentOapp.data &&
              !this.currentOapp.type.startsWith('heatmap') &&
              !this.currentOapp.type === 'custom'
            )
              space.style.fill = '(#hash-pattern-noData)';
            else space.style.fill = 'white';
          });
        }
        curSelection[buildingUuid][floorUuid].forEach(s => {
          const space = this.$palmier.getSpace(s.idIFC);
          if (!space) return;
          if (
            this.currentOapp &&
            this.currentOapp.data &&
            !this.currentOapp.type.startsWith('heatmap') &&
            !this.currentOapp.type === 'custom'
          )
            space.style.fill = 'url(#hash-pattern-noData)';
          else space.style.fill = 'white';
        });
      }
    },
    resetSpacesStyle() {
      const spaceList = [...document.querySelectorAll('.space')];
      const spaceListWhitelist = spaceList.filter(space =>
        this.currentFloors.some(floor => floor.whitelist.find(whiteListSpace => whiteListSpace === space.id))
      );
      spaceListWhitelist.forEach(s => {
        s.style.fill = this.colors.silver;
      });
    },
    spaceIsSelected(spaceIdIFC) {
      const curSelection = this.filteredSelection;
      if (!curSelection || Object.keys(curSelection).length <= 0) return false;
      for (const floorKey in curSelection[this.currentBuilding.uuid]) {
        const floor = curSelection[this.currentBuilding.uuid][floorKey];
        const space = floor.find(s => s.idIFC === spaceIdIFC);
        if (space) return true;
      }
      return false;
    },
    getFloorFromSpaceId(spaceId) {
      return this.currentBuilding.next.find(f => {
        const space = f.next.find(s => s.uuid === spaceId);
        return space ? true : false;
      });
    },
    colorSpacesWithData(currentCursorTime) {
      this.updateSpaceSelectionStyle();
      if (
        !this.currentOapp ||
        !this.currentOapp.data ||
        this.currentOapp.type.startsWith('heatmap') ||
        (!currentCursorTime && !this.liveMode) ||
        !this.currentOapp.data.GoodBadDetail
      )
        return;
      const spacesValues = this.liveMode
        ? this.currentOapp.data.GoodBadDetail.spaces
        : this.currentOapp.data.GoodBadDetail[currentCursorTime].spaces;
      // filter spaceValues according to whitelisted spaces
      const whiteListedSpaceData = Object.keys(spacesValues).reduce((acc, spaceUuid) => {
        if (this.currentWhitelistedSpaces.includes(spaceUuid)) acc[spaceUuid] = spacesValues[spaceUuid];
        return acc;
      }, {});
      for (const spaceIdIFC in whiteListedSpaceData) {
        const space = this.$palmier.getSpace(spaceIdIFC);
        if (space) {
          const color =
            spacesValues[spaceIdIFC].goodBad !== null ? this.colorScale(spacesValues[spaceIdIFC].goodBad) : 'url(#hash-pattern-noData)';
          space.style.fill = color;
        }
      }
    },
    centerSpace(space, floor) {
      const floorBox = floor.getBoundingClientRect();
      const spaceBox = space.getBoundingClientRect();
      const viewerCenter = { top: this.$refs.viewer.offsetHeight / 2, left: this.$refs.viewer.offsetWidth / 2 };
      const spaceCenter = { top: spaceBox.height / 2, left: spaceBox.width / 2 };
      const floorPosY = floorBox.top - spaceBox.top + viewerCenter.top - spaceCenter.top;
      const floorPosX = floorBox.left - spaceBox.left + viewerCenter.left - spaceCenter.left;
      floor.style.top = `${floorPosY}px`;
      floor.style.left = `${floorPosX}px`;
    },
    updateNamesRotation() {
      const names = document.querySelectorAll('text.space-name');
      for (const n of names) {
        n.style.transformOrigin = `${n.getAttribute('x')}px ${n.getAttribute('y')}px`;
        n.style.transform = `rotateZ(${-this.currentOrientationDegree + (this.mode === 'flat' ? 45 : 0)}deg)`;
      }
    },
    createFloorsCanvas() {
      this.currentFloors.forEach((f, idx) => {
        const canvas = document.createElement('CANVAS');
        const floorEl = this.$palmier.getFloor(idx);
        const floorBox = floorEl.getBoundingClientRect();
        const floorHulls = floorEl.getElementsByClassName('floor-hull');
        if (floorHulls.length) {
          const points = floorHulls[0].getAttribute('points');
          const pointsArray = points.split(',').map(p => p.trim().split(' '));
          let polygon = 'polygon(';
          pointsArray.forEach((p, idx) => {
            const point = floorEl.firstElementChild.createSVGPoint();
            const matrix = floorEl.firstElementChild.getCTM();
            point.x = parseFloat(p[0]);
            point.y = parseFloat(p[1]);
            const svgP = point.matrixTransform(matrix);
            polygon += `${svgP.x}px ${svgP.y}px${idx < pointsArray.length - 1 ? ', ' : ')'}`;
          });
          canvas.style.clipPath = polygon;
        }
        canvas.setAttribute('id', `canvas${idx}`);
        canvas.setAttribute('width', floorBox.width);
        canvas.setAttribute('height', floorBox.height);
        canvas.classList.add('canvas');
        this.updateFloorStyle(idx, this.currentOrientationDegree, this.floor.zoom.min);
        if (floorEl.firstElementChild) canvas.style.transform = floorEl.firstElementChild.style.transform;
        floorEl.appendChild(canvas);
      });
    },
  },
};
