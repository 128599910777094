export default {
  name: 'PropertiesEdition',
  computed: {
    currentWhitelistedSpaces() {
      return this.$store.getters['building/currentWhitelistedSpaces'];
    },
    currentProperty() {
      return this.$store.state.zones.properties.selectedProperty;
    },
    currentBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
  },
  methods: {
    propertiesEditionMode() {
      this.$store.commit('zones/properties/selectProperty', null);
      const spacesToWhitelist = this.currentWhitelistedSpaces;
      const allSpaces = this.$palmier.spaces({ whitelist: spacesToWhitelist });
      allSpaces.apply({
        style: s => (s.fill = this.colors.silver),
      });
    },
    propertiesEditionClick(space, evt, keys) {
      if (space && this.currentProperty && !this.drag.mousemoved) {
        const curSpace = this.getSpaceFromIdIFC(space.id);
        if (!curSpace) return;
        this.$store.dispatch('zones/properties/update', { space: curSpace, property: this.currentProperty });
      }
      this.drag.mousemoved = false;
    },
    getSpaceFromIdIFC(spaceIdIFC) {
      let space = null;
      this.currentBuilding.next.find(f => {
        f.next.find(s => {
          if (s.idIFC === spaceIdIFC) return (space = s);
        });
      });
      return space;
    },
  },
};
