<template>
  <div class="floor-level"
       @mouseout="setNoFloorHovered">
    <div v-for="(floor, idx) in currentFloors"
         :key="idx"
         :style="squareStyle(idx)"
         :class="squareClass(idx, floor)"
         class="floor-level__svg-container">
      <svg class="floor-level__svg"
           viewBox="0 0 50 50">
        <defs>
          <linearGradient id="gradient-white"
                          x1="100%"
                          y1="0%"
                          x2="0%"
                          y2="100%">
            <stop offset="10%"
                  class="gradient-start" />
            <stop offset="90%"
                  class="gradient-stop" />
          </linearGradient>
          <linearGradient id="gradient-grey"
                          x1="100%"
                          y1="0%"
                          x2="0%"
                          y2="100%">
            <stop offset="10%"
                  class="gradient-start" />
            <stop offset="90%"
                  class="gradient-stop" />
          </linearGradient>
          <linearGradient id="gradient-primary"
                          x1="100%"
                          y1="0%"
                          x2="0%"
                          y2="100%">
            <stop offset="10%"
                  class="gradient-start" />
            <stop offset="90%"
                  class="gradient-stop" />
          </linearGradient>
          <linearGradient id="gradient-silver"
                          x1="100%"
                          y1="0%"
                          x2="0%"
                          y2="100%">
            <stop offset="10%"
                  class="gradient-start" />
            <stop offset="90%"
                  class="gradient-stop" />
          </linearGradient>
        </defs>
        <rect :x="rectPosition.x"
              :y="rectPosition.y"
              width="70%"
              height="70%"
              class="floor-level__floor"
              @click="setCurrentFloorIndex(idx)"
              @mouseover="setFloorHoveredIndex(idx)" />
      </svg>
      <span v-if="showFloorLabel(idx)"
            class="floor-level__label">
        {{ floor.name }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FloorLevel',
  data() {
    return {
      floorHovered: null,
      rectPosition: {
        x: '15%',
        y: '15%',
      },
    };
  },
  computed: {
    currentFloorIndex() {
      return this.$store.state.building.selectedFloor;
    },
    currentFloors() {
      return this.$store.state.building.selectedBuilding.next || null;
    },
    filteredSelection() {
      return this.$store.getters['selections/filteredBuildings'];
    },
    selectedBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    totalLevel() {
      return this.currentFloors.length;
    },
    floorsInfo() {
      return this.currentFloors.map(f => {
        return {
          name: f.name,
          level: f.level,
        };
      });
    },
  },
  methods: {
    squareStyle(index) {
      return {
        'z-index': this.totalLevel - index,
      };
    },
    squareClass(index, floor) {
      const floorType = this.getFloorType(floor.name);
      return {
        'floor-level__svg-container--current': this.floorHovered === null && index === this.currentFloorIndex,
        'floor-level__svg-container--hovered': index === this.floorHovered,
        'floor-level__svg-container--ground': floorType === 'ground',
        'floor-level__svg-container--basement': floorType === 'basement',
        'floor-level__svg-container--not-selected': !this.floorIsSelected(floor),
      };
    },
    floorIsSelected(floor) {
      if (!(this.selectedBuilding.uuid in this.filteredSelection)) return true;
      return floor.uuid in this.filteredSelection[this.selectedBuilding.uuid];
    },
    getFloorType(level) {
      let levelNum = level.split('_R')[1] || level.split('Niveau_')[1];
      if (levelNum === 'dC' || parseInt(levelNum) === 0) return 'ground';
      levelNum = parseInt(levelNum);
      if (levelNum < 0) return 'basement';
      else return 'floor';
    },
    showFloorLabel(index) {
      if (this.floorHovered !== null && this.floorHovered === index) return true;
      if (this.floorHovered === null && index === this.currentFloorIndex) return true;
      return false;
    },
    setFloorHoveredIndex(index) {
      this.floorHovered = index;
    },
    setNoFloorHovered() {
      this.floorHovered = null;
    },
    setCurrentFloorIndex(index) {
      this.$emit('setFloorLevel', index);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

$square-size = 50px
$square-margin = 10px

.floor-level
  z-index 2000
  margin-bottom 25px
  padding 8px
  user-select none
  .floor-level__svg-container
    position relative
    display flex
    align-items center
    margin-bottom 'calc(%s - %s)' % ($square-margin $square-size)
    pointer-events none
    .floor-level__svg
      width $square-size
      height $square-size
    .floor-level__floor
      position relative
      cursor pointer
      transform rotateX(-60deg) rotateY(0deg) rotateZ(-45deg)
      transform-origin center center 0
      pointer-events auto
      fill url('#gradient-white')
    &--current, &--hovered
      .floor-level__floor
        fill url('#gradient-primary') !important
    &--basement
      .floor-level__floor
        fill url('#gradient-grey')
    &--not-selected
      .floor-level__floor
        fill url('#gradient-silver')
    .floor-level__label
      position relative
      display flex
      justify-content center
      align-items center
      margin-left 24px
      padding 2px 8px
      border-top-right-radius 10px
      border-bottom-right-radius 10px
      background-color $black
      color white
      font-weight 500
      font-size $fs-body
      &:after
        position absolute
        left -10px
        width 0
        height 0
        border-width 9.5px 10px 9.5px 0
        border-style solid
        border-color transparent $black transparent transparent
        content ''

.gradient-start, .gradient-stop
  stop-opacity 1

#gradient-white
  .gradient-start
    stop-color darken(white, 5%)
  .gradient-stop
    stop-color white

#gradient-grey
  .gradient-start
    stop-color darken($dark, 20%)
  .gradient-stop
    stop-color $grey

#gradient-silver
  .gradient-start
    stop-color lighten($silver, 5%)
  .gradient-stop
    stop-color $light

#gradient-primary
  .gradient-start
    stop-color darken($primary, 20%)
  .gradient-stop
    stop-color $primary
</style>
