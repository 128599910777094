<template>
  <div @click.stop="">
    <q-icon class="shortcuts"
            name="mdi-information-outline"
            size="30px">
      <q-popover class="shortcuts-popover"
                 anchor="bottom left"
                 self="top right">
        <span class="popover__title">
          {{$t('components.shortcuts.name')}}
        </span>
        <!-- GLOBAL SHORTCUTS -->
        <div class="popover__main">
          <span class="popover__subtitle">
            {{$t('components.shortcuts.navigation.name')}}
          </span>
          <div class="shortcut">
            <div class="shortcut__keys">
              <span class="key">
                {{$t('components.shortcuts.keys.ctrl')}}
              </span>
              <span class="key-plus">
                +
              </span>
              <span class="key">
                {{$t('components.shortcuts.keys.wheel')}}
                <q-icon name="mdi-mouse" />
              </span>
            </div>
            <div class="shortcut__action">
              {{$t('components.shortcuts.navigation.zoomCurrentFloor')}}
            </div>
          </div>
          <div class="shortcut">
            <div class="shortcut__keys">
              <span class="key">
                {{$t('components.shortcuts.keys.ctrl')}}
              </span>
              <span class="key-plus">
                +
              </span>
              <span class="key">
                {{$t('components.shortcuts.keys.mouseLeft')}}
                <q-icon name="mdi-mouse" />
              </span>
              <span class="key-plus">
                +
              </span>
              <span class="key">
                <q-icon name="fas fa-arrows-alt" />
              </span>
            </div>
            <div class="shortcut__action">
              {{$t('components.shortcuts.navigation.moveCurrentFloor')}}
            </div>
          </div>
          <div class="shortcut">
            <div class="shortcut__keys">
              <span class="key">
                {{$t('components.shortcuts.keys.ctrl')}}
              </span>
              <span class="key-plus">
                +
              </span>
              <span class="key">
                {{$t('components.shortcuts.keys.arrows')}}
                <q-icon name="fas fa-arrows-alt" />
              </span>
            </div>
            <div class="shortcut__action">
              {{$t('components.shortcuts.navigation.moveCurrentFloor')}}
            </div>
          </div>
          <div class="shortcut">
            <div class="shortcut__keys">
              <span class="key">
                {{$t('components.shortcuts.keys.wheel')}}
                <q-icon name="mdi-mouse" />
              </span>
            </div>
            <div class="shortcut__action">
              {{$t('components.shortcuts.navigation.nextFloorUpDown')}}
            </div>
          </div>
          <div class="shortcut">
            <div class="shortcut__keys">
              <span class="key">
                {{$t('components.shortcuts.keys.pageUp')}}
                <q-icon name="mdi-chevron-double-up" />
              </span>
              <span class="key-plus">
                {{$t('components.shortcuts.commons.or')}}
              </span>
              <span class="key">
                {{$t('components.shortcuts.keys.pageDown')}}
                <q-icon name="mdi-chevron-double-down" />
              </span>
            </div>
            <div class="shortcut__action">
              {{$t('components.shortcuts.navigation.nextFloorUpDown')}}
            </div>
          </div>

          <!-- SELECTION SHORTCUTS -->
          <span v-if="mode === 'navigation'">
            <span class="popover__subtitle">
              {{$t('components.shortcuts.selection.name')}}
            </span>
            <div class="shortcut">
              <div class="shortcut__keys">
                <span class="key">
                  {{$t('components.shortcuts.keys.mouseLeft')}}
                  <q-icon name="mdi-mouse" />
                </span>
              </div>
              <div class="shortcut__action">
                {{$t('components.shortcuts.selection.selectSpace')}}
              </div>
            </div>
            <div class="shortcut">
              <div class="shortcut__keys">
                <span class="key">
                  {{$t('components.shortcuts.keys.ctrl')}}
                </span>
                <span class="key-plus">
                  +
                </span>
                <span class="key">
                  {{$t('components.shortcuts.keys.mouseLeft')}}
                  <q-icon name="mdi-mouse" />
                </span>
              </div>
              <div class="shortcut__action">
                {{$t('components.shortcuts.selection.selectMultiSpace')}}
              </div>
            </div>
            <div class="shortcut">
              <div class="shortcut__keys">
                <span class="key">
                  {{$t('components.shortcuts.keys.mouseRight')}}
                  <q-icon name="mdi-mouse" />
                </span>
              </div>
              <div class="shortcut__action">
                {{$t('components.shortcuts.selection.spaceDetails')}}
              </div>
            </div>
          </span>

          <!-- EDITION SHORTCUTS -->
          <span v-if="mode === 'deviceEdition'">
            <span class="popover__subtitle">
              {{$t('components.shortcuts.edition.name')}}
            </span>
            <div class="shortcut">
              <div class="shortcut__keys">
                <span class="key">
                  <q-icon name="mdi-mouse" />
                </span>
                <span class="key-plus">
                  +
                </span>
                <span class="key">
                  {{$t('components.shortcuts.edition.inSpace')}}
                </span>
              </div>
              <div class="shortcut__action">
                {{$t('components.shortcuts.edition.addDeviceInSpace')}}
              </div>
            </div>
            <div class="shortcut">
              <div class="shortcut__keys">
                <span class="key">
                  <q-icon name="mdi-mouse" />
                </span>
                <span class="key-plus">
                  +
                </span>
                <span class="key">
                  {{$t('components.shortcuts.edition.outSpace')}}
                </span>
              </div>
              <div class="shortcut__action">
                {{$t('components.shortcuts.edition.leaveEditionMode')}}
              </div>
            </div>
          </span>
        </div>
      </q-popover>
    </q-icon>
  </div>
</template>

<script>
export default {
  name: 'Shortcuts',
  props: {
    mode: {
      type: String,
      default: 'navigation',
    },
    shortcutsOpened: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.shortcuts
  cursor pointer

.shortcuts-popover
  padding 16px
  width fit-content
  box-shadow none
  .popover__title
    text-transform uppercase
    letter-spacing 0.5px
    font-weight 700
    font-size 1em
  .popover__main
    display flex
    flex-direction column
    margin-top 5px
    .popover__subtitle
      margin-top 10px
      text-transform uppercase
      letter-spacing 0.5px
      font-weight 700
      font-size 0.8em
    .shortcut
      display flex
      margin 8px 0
      &:last-child
        margin-bottom 0px
      .shortcut__keys
        display flex
        flex-basis 60%
        margin-right 16px
        text-align right
        white-space nowrap
        .key
          display flex
          align-items center
          padding 2px 8px
          border-radius 6px
          background-color $dark
          color white
          letter-spacing 1px
          font-weight 700
        .key-plus
          margin 0 4px
          font-size 1.3em
      .shortcut__action
        flex-basis 70%
        text-align right
        font-size 1.1em
</style>
