export default {
  name: 'TicketsMode',
  computed: {
    tickets() {
      return this.$store.state.tickets.collection;
    },
    currentFloors() {
      return this.$store.state.building.selectedBuilding.next || null;
    },
    currentBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    user() {
      return this.$store.state.user.user;
    },
  },
  watch: {
    tickets(val) {
      this.removeDeviceDot('tempDot');
      this.popup.show = false;
      this.placeTicketDotsOnMap();
    },
  },
  methods: {
    ticketsMode() {
      this.setSidebarInState(false);
      this.setSidebarOutState('medium');
      this.floor.zoom.value = this.floor.zoom.min;
      this.showMapControls = true;
      this.setDisplayMode('iso');
      this.updateSpaceSelectionStyle();
      this.removeDeviceDot('tempDot');
      this.tickets.forEach(t => {
        this.removeDeviceDot(t.shortId);
      });
      setTimeout(() => {
        this.placeTicketDotsOnMap();
      }, 1000);
    },
    placeTicketDotsOnMap() {
      this.tickets.forEach(t => {
        if (!document.getElementById(t.shortId)) {
          if (t.x === null || t.y === null) return;
          const index = this.currentFloors.findIndex(f => f.uuid === t.floorUuid);
          const floorElem = document.getElementById(`floor${index}`);
          if (!floorElem) return;
          const color = this.colors.darkPink;
          const dot = this.createDeviceDot(t.shortId, t.x, t.y, null, 8, color);
          floorElem.firstElementChild.appendChild(dot.$el);
        }
      });
    },
    ticketClick(space, evt, keys) {
      this.popup.show = false;
      if (this.isDevice(evt)) {
        if (evt.target.id !== 'tempDot') {
          this.removeDeviceDot('tempDot');
        }
        this.currentTicket = evt.target.id;
        this.showPopup(evt.clientX, evt.clientY);
      }
    },
    ticketsRightClick(room, evt, keys) {
      if (!this.user.featuresRights.ticket.WRITE) return;
      this.removeDeviceDot('tempDot');
      const floor = this.currentFloors[this.currentFloorIndex];
      if (!floor) return;
      room = this.$palmier.getSpace(evt.target.id);
      if (!room) return;
      this.$store.commit('building/setSelectedSpace', room);
      const find = floor.whitelist.find(whiteListRoom => whiteListRoom === room.id);
      if (!find) return;
      this.$emit('ticketRightClick', {
        roomUuid: room.id,
        buildingUuid: this.currentBuilding.uuid,
        floorUuid: floor.uuid,
        x: evt.clientX,
        y: evt.clientY,
      });
      const floorElem = document.getElementById(`floor${this.currentFloorIndex}`);
      if (!floorElem) return;
      const dot = this.createDeviceDot('tempDot', evt.clientX, evt.clientY, floorElem.firstElementChild);
      floorElem.firstElementChild.appendChild(dot.$el);
      this.currentTicket = 'tempDot';
      this.showPopup(evt.clientX, evt.clientY);
    },
    ticketsWheel(space, evt, key) {
      this.popup.show = false;
      this.popup.error = null;
      const scrollUp = evt.deltaY < 0 ? true : false;
      if (evt.ctrlKey) {
        if (scrollUp) this.floor.zoom.value += this.floor.zoom.step;
        else this.floor.zoom.value -= this.floor.zoom.step;
      } else {
        if (scrollUp) this.animFloorChange('up');
        else this.animFloorChange('down');
      }
    },
    ticketsMouseMove(space, evt, keys) {
      this.drag.ctrl = keys.Control;
      if (this.drag.lock) {
        // drag the floor
        const floor = this.$palmier.getFloor(this.currentFloorIndex);
        this.drag.move = true;
        this.drag.mousemoved = true;
        this.popup.show = false;
        this.removeDeviceDot('tempDot');
        this.$emit('click');
        this.dragNodeElement(floor, evt);
      } else this.drag.move = false;
    },
    ticketsPageUp(space, evt, key) {
      this.popup.show = false;
      this.removeDeviceDot('tempDot');
      this.$emit('click');
      this.animFloorChange('down');
    },
    ticketsPageDown(space, evt, key) {
      this.popup.show = false;
      this.removeDeviceDot('tempDot');
      this.$emit('click');
      this.animFloorChange('up');
    },
    ticketsCtrlArrows(space, evt, key) {
      this.popup.show = false;
      this.removeDeviceDot('tempDot');
      this.$emit('click');
      const floor = document.querySelector(`#floor${this.currentFloorIndex}`);
      const move = key === 'down' || key === 'right' ? 50 : -50;
      if (key === 'up' || key === 'down') floor.style.top = `${parseInt(floor.style.top) + move}px`;
      else floor.style.left = `${parseInt(floor.style.left) + move}px`;
    },
  },
};
