<template>
  <div ref="viewer"
       v-touch-pan.noMouse="onSwipeEvent"
       v-hammer:pinch="onPinchEvent"
       tabindex="-1"
       class="building-viewer"
       @wheel="preventWheel"
       @mousedown.left="evt => handleActions(null, evt, null, 'mousedownleft')"
       @contextmenu.prevent="evt => handleActions(null, evt, null, 'rightclick')"
       @keyup="evt => handleActions(null, evt, null, 'ctrlup')"
       @keydown.ctrl="evt => handleActions(null, evt, null, 'ctrldown')"
       @keydown.ctrl.left="evt => handleActions(null, evt, 'left', 'ctrlarrow')"
       @keydown.ctrl.right="evt => handleActions(null, evt, 'right', 'ctrlarrow')"
       @keydown.ctrl.up="evt => handleActions(null, evt, 'up', 'ctrlarrow')"
       @keydown.ctrl.down="evt => handleActions(null, evt, 'down', 'ctrlarrow')"
       @keydown.page-down.exact="evt => handleActions(null, evt, null, 'pageup')"
       @keydown.page-up.exact="evt => handleActions(null, evt, null, 'pagedown')"
       @keydown.ctrl.109.prevent="evt => handleActions(null, evt, null, 'ctrlminus')"
       @keydown.ctrl.107.prevent="evt => handleActions(null, evt, 'down', 'ctrlplus')"
       @touchstart="onTouchStart">
    <div v-if="$q.platform.is.desktop && currentBuilding"
         class="building-viewer__controls building-viewer__controls--left">
      <FloorLevelControl v-if="showMapControls"
                         class="building-viewer__level-control"
                         @setFloorLevel="setFloorLevel" />
      <FloorRotationControl v-if="showMapControls"
                            class="building-viewer__rotation-control" />
    </div>
    <div v-if="$q.platform.is.desktop"
         class="building-viewer__controls building-viewer__controls--right">
      <ShortcutsControl :mode="viewerMode" />
    </div>

    <div v-show="readyToAnimate && readyToload"
         id="buildingContainer">
      <HashPatterns />
      <floor v-for="(f, index) in currentFloors"
             :key="index"
             :ref="`floor`+index"
             :floor="f"
             :floorId="index"
             :class="{active:currentFloorIndex===index}"
             :zoomed="floorIsZoomed" />
    </div>

    <PopUp v-if="$q.platform.is.desktop && popup.show"
           ref="popup"
           :positionX="popup.positionX"
           :topPosition="popup.top"
           :leftPosition="popup.left"
           :positionY="popup.positionY"
           :edition="popup.edition">
      <SpaceInfo v-if="!currentDevice.uuid && currentSpace && !currentTicket"
                 ref="spaceInfo"
                 :space="currentSpace"
                 :spaceDevices="spaceDevices"
                 :dataInfo="currentSpaceData"
                 :error="popup.error" />
      <DeviceInfo v-if="currentDevice.uuid"
                  :device="currentDevice"
                  @edit="popup.edition = !popup.edition"
                  @save="saveDevice()"
                  @move="moveDeviceDot"
                  @remove="removeDeviceDot(currentDevice.uuid)" />
      <TicketInfo v-if="currentTicket"
                  :ticketId="currentTicket"
                 :space="currentSpace"
                  @click="val => $emit('ticketClick', val)" />
    </Popup>

    <div class="building-viewer__display-container">
      <qt-spinner v-if="currentBuilding && !readyToAnimate"
                  :size="80"
                  class="spinner-loader" />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import chroma from 'chroma-js';

// Mixins
import UtilsMixin from '@/app/mixins/utils';
import ColorsMixin from '@/app/mixins/colors';
import DebounceMixin from '@/app/mixins/debounce';
import NavigationModeMixin from '@/app/mixins/building-viewer/navigation';
import SimpleModeMixin from '@/app/mixins/building-viewer/simple';
import PropertiesEditionModeMixin from '@/app/mixins/building-viewer/properties';
import DeviceModeMixin from '@/app/mixins/building-viewer/device';
import ZoneModeMixin from '@/app/mixins/building-viewer/zone';
import MobileMixin from '@/app/mixins/building-viewer/mobile';
import TicketsModeMixin from '@/app/mixins/building-viewer/tickets';
// viewer actions
import BuildingActionsMixins from '@/app/mixins/building-viewer/actions/building';
import SpaceActionsMixins from '@/app/mixins/building-viewer/actions/space';
import DeviceActionsMixins from '@/app/mixins/building-viewer/actions/device';

import HashPatterns from '@/app/views/building-viewer/components/hash-patterns.vue';

// Controls components
import Floor from '@/app/components/floors/floor.vue';
import FloorLevelControl from '@/app/views/building-viewer/components/floor-control.vue';
import FloorRotationControl from '@/app/views/building-viewer/components/view-control.vue';
import ShortcutsControl from '@/app/pages/data/components/shortcuts.vue';
import QtSpinner from '@/app/components/ui/spinner.vue';

// Popup components
import PopUp from '@/app/components/ui/pop-up.vue';
import SpaceInfo from '@/app/components/spaces/space-info.vue';
import DeviceInfo from '@/app/components/devices/device-info.vue';
import TicketInfo from '@/app/views/building-viewer/components/ticket-info.vue';

export default {
  name: 'BuildingViewwer',
  components: {
    Floor,
    FloorLevelControl,
    FloorRotationControl,
    ShortcutsControl,
    QtSpinner,
    PopUp,
    SpaceInfo,
    DeviceInfo,
    TicketInfo,
    HashPatterns,
  },
  mixins: [
    UtilsMixin,
    DebounceMixin,
    ColorsMixin,
    NavigationModeMixin,
    SimpleModeMixin,
    DeviceModeMixin,
    ZoneModeMixin,
    MobileMixin,
    BuildingActionsMixins,
    SpaceActionsMixins,
    DeviceActionsMixins,
    PropertiesEditionModeMixin,
    TicketsModeMixin,
  ],
  props: {
    editorMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMapControls: true,
      buildingController: null,
      actions: {
        navigation: {
          created: this.navigationMode,
          wheel: this.navigationWheel,
          click: this.navigationClick,
          rightclick: this.navigationRightClick,
          mousedownleft: this.navigationLeftMouseDown,
          mousemove: this.navigationMouseMove,
          mouseup: this.navigationMouseUp,
          mouseenter: this.navigationMouseenter,
          mouseout: this.navigationMouseout,
          pageup: this.navigationPageUp,
          pagedown: this.navigationPageDown,
          ctrlarrow: this.navigationCtrlArrows,
          ctrlplus: this.navigationCtrlPlus,
          ctrlminus: this.navigationCtrlMinus,
          ctrldown: this.navigationCtrlDown,
          ctrlup: this.navigationKeyUp,
        },
        simple: {
          created: this.simpleMode,
          wheel: this.navigationWheel,
          mousedownleft: this.navigationLeftMouseDown,
          mousemove: this.navigationMouseMove,
          mouseup: this.navigationMouseUp,
          pageup: this.navigationPageUp,
          pagedown: this.navigationPageDown,
          ctrlarrow: this.navigationCtrlArrows,
        },
        deviceEdition: {
          created: this.deviceEditionMode,
          click: this.editionClick,
          mousemove: this.editionMousemove,
          mousedownleft: this.navigationLeftMouseDown,
          mouseup: this.navigationMouseUp,
          ctrlarrow: this.navigationCtrlArrows,
          wheel: this.editionWheel,
          ctrlplus: this.navigationCtrlPlus,
          ctrlminus: this.navigationCtrlMinus,
        },
        zoneEdition: {
          created: this.zoneEditionMode,
          click: this.zoneEditionClick,
          wheel: this.navigationWheel,
          mousedownleft: this.navigationLeftMouseDown,
          mousemove: this.navigationMouseMove,
          mouseup: this.navigationMouseUp,
          pageup: this.navigationPageUp,
          pagedown: this.navigationPageDown,
          ctrlarrow: this.navigationCtrlArrows,
          rightclick: this.navigationRightClick,
        },
        propertiesEdition: {
          created: this.propertiesEditionMode,
          wheel: this.navigationWheel,
          mousedownleft: this.navigationLeftMouseDown,
          mousemove: this.navigationMouseMove,
          mouseup: this.navigationMouseUp,
          pageup: this.navigationPageUp,
          pagedown: this.navigationPageDown,
          ctrlarrow: this.navigationCtrlArrows,
          click: this.propertiesEditionClick,
          rightclick: this.navigationRightClick,
        },
        tickets: {
          created: this.ticketsMode,
          wheel: this.ticketsWheel,
          mousedownleft: this.navigationLeftMouseDown,
          mousemove: this.ticketsMouseMove,
          mouseup: this.navigationMouseUp,
          pageup: this.ticketsPageUp,
          pagedown: this.ticketsPageDown,
          ctrlarrow: this.ticketsCtrlArrows,
          click: this.ticketClick,
          rightclick: this.ticketsRightClick,
        },
      },
      floor: {
        zoom: {
          value: 1,
          min: 1,
          max: 40,
          step: 0.2,
        },
      },
      currentDevice: {
        uuid: null,
        description: '',
        positionX: 0,
        positionY: 0,
        created: false,
      },
      currentTicket: null,
      currentHoveredSpaceColor: null,
      popup: {
        edition: false,
        show: false,
        move: true,
        left: true,
        top: true,
        positionX: 0,
        positionY: 0,
        error: null,
      },
      // old data to remove if possible
      viewer: null,
      viewportHeight: 500,
      scale: 1,
      scalemin: 1,
      scalemax: 5,
      overedItem: null,
      readyToAnimate: false,
      readyToload: false,
      lockZoomOrigin: false,
      lockChangeTransformOrigin: false,
      drag: {
        move: false,
        lock: false,
        mousemoved: false,
        ctrl: false,
      },
      xmouse: 0,
      ymouse: 0,
      nodeX: 0,
      nodeY: 0,
      lockedItem: null,
      swipeDisabled: false,
    };
  },
  computed: {
    currentOapp() {
      return this.$store.state.oapp.currentOapp;
    },
    ...mapState('ui', {
      currentOrientationDegree: state => state.floors.currentOrientationDegree,
      minZoom: state => state.floors.minZoom,
      mode: state => state.floors.displayMode,
      viewerMode: state => state.viewerMode,
      navTabsOpened: state => state.navTabsOpened,
      colorScales: state => state.colorScales,
    }),
    currentSpaceSelection() {
      return this.$store.state.building.selectedSpace;
    },
    selectInFilters() {
      return this.$store.getters['selections/selectInFilters'];
    },
    currentFloorIndex() {
      return this.$store.state.building.selectedFloor;
    },
    currentTimelineCursorDate() {
      return this.$store.state.selections.time.timeline;
    },
    currentFloors() {
      return this.$store.state.building.selectedBuilding.next || null;
    },
    currentSpace() {
      return this.$store.getters['building/selectedSpace'];
    },
    currentFilters() {
      return this.$store.state.selections.filters;
    },
    filteredSelection() {
      return this.$store.getters['selections/filteredBuildings'];
    },
    liveMode() {
      return this.$store.state.selections.time.range === 'live';
    },
    currentBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    spaceDevices() {
      // return devices list in selected space
      return (this.currentBuilding.devices).filter(device => device.space === this.currentSpace.uuid);
    },
    currentColorScale() {
      return this.colorScales[this.currentOapp.type];
    },
    colorScale() {
      return chroma
        .scale(this.currentColorScale)
        .domain([this.currentOapp.limits[this.currentOapp.type].min, this.currentOapp.limits[this.currentOapp.type].max]);
    },
    currentSpaceData() {
      if (
        !this.currentOapp ||
        !this.currentOapp.data ||
        !this.currentOapp.data.GoodBadDetail ||
        (!this.currentTimelineCursorDate && !this.liveMode) ||
        !this.currentSpace
      )
        return null;
      let spaces;
      if (this.liveMode) spaces = this.currentOapp.data.GoodBadDetail.spaces;
      else spaces = this.currentOapp.data.GoodBadDetail[this.currentTimelineCursorDate].spaces;
      if (!spaces || !Object.keys(spaces).includes(this.currentSpace.idIFC)) return null;
      return spaces[this.currentSpace.idIFC].values;
    },
    buildingViewerClass() {
      return { 'grab-cursor': this.drag.ctrl, 'grabbing-cursor': this.drag.lock };
    },
    showBuildingName() {
      return !['simple', 'zoneEdition', 'propertiesEdition'].includes(this.viewerMode);
    },
    floorIsZoomed() {
      return this.floor.zoom.value > this.floor.zoom.min;
    },
  },
  watch: {
    viewerMode(mode) {
      this.buildingController.removeEvents();
      this.handleViewerModeCreated(mode);
      this.updateDeviceDotMode(mode);
    },
    currentTimelineCursorDate(currentCursorTime) {
      this.colorSpacesWithData(currentCursorTime);
    },
    filteredSelection: {
      handler(selection) {
        this.updateSpaceSelectionStyle();
        this.updateDeviceDotSelection();
      },
      deep: true,
    },
    currentFilters: {
      handler(val) {
        if (this.popup.error) {
          this.popup.show = false;
          this.popup.error = null;
        }
      },
      deep: true,
    },
    'currentOapp.data': {
      handler(data) {
        if (data && data.GoodBadDetail && Object.keys(data.GoodBadDetail).length > 0 && this.$store.state.ui.viewerMode !== 'tickets')
          this.colorSpacesWithData(this.currentTimelineCursorDate);
        else {
          this.updateSpaceSelectionStyle();
          this.updateDeviceDotSelection();
        }
      },
      deep: true,
    },
    'floor.zoom.value'(zoom) {
      if (zoom > this.floor.zoom.max) this.floor.zoom.value = this.floor.zoom.max;
      if (zoom < this.floor.zoom.min) this.floor.zoom.value = this.floor.zoom.min;
      this.updateFloorStyle(this.currentFloorIndex, this.currentOrientationDegree, this.floor.zoom.value);
    },
    currentOrientationDegree() {
      this.popup.show = false;
      this.updateFloors();
      this.updateNamesRotation();
    },
    minZoom() {
      this.popup.show = false;
      this.floor.zoom.min = this.minZoom;
      this.floor.zoom.value = this.minZoom;
      this.updateFloors();
    },
    navTabsOpened() {
      this.anim(this.currentFloorIndex);
    },
    currentFloorIndex() {
      this.anim(this.currentFloorIndex);
    },
    mode() {
      this.popup.show = false;
      this.updateFloors();
      this.updateNamesRotation();
      this.anim(this.currentFloorIndex);
    },
    currentOapp(oapp) {
      if (!oapp) this.updateSpaceSelectionStyle();
    },
    // old watch to remove if possible
    readyToAnimate(value) {
      if (this.readyToAnimate === true) {
        setTimeout(() => {
          if (this.$q.platform.is.mobile) {
            this.$store.dispatch('ui/enableFlatDisplayMode');
            this.anim(this.currentFloorIndex);
          } else this.anim('*');
          this.createFloorsCanvas();
        }, 10);
      }
      this.colorSpacesWithData(this.currentTimelineCursorDate);
      this.handleViewerModeCreated(this.viewerMode);
    },
  },
  mounted() {
    this.handleViewerModeCreated(this.viewerMode);
    this.setDisplayMode('iso');
    this.initZoom();
    this.updateFloors();
    const initialRotation = this.currentBuilding.metadata?.preferences?.rotation || -45;
    this.$store.commit('ui/SET_CURRENT_FLOORS_ORIENTATION_DEGREE', initialRotation);
    this.resetFloorsPlacement(true);
    this.updateNamesRotation();
    this.viewportHeight = this.$refs.viewer.clientHeight;
    this.viewer = this.$refs.viewer;

    if (['navigation', 'deviceEdition'].includes(this.viewerMode)) {
      this.setDevicesOnSpaces(this.currentBuilding.devices);
      this.updateDeviceDotMode(this.viewerMode);
      this.updateDeviceDotSelection();
    }
    this.buildingController = this.$palmier.building({
      events: {
        click: (space, evt, keys) => this.handleActions(space, evt, keys, 'click'),
        wheel: (space, evt, keys) => this.handleActions(space, evt, keys, 'wheel'),
        mouseout: (space, evt, keys) => this.handleActions(space, evt, keys, 'mouseout'),
        mousemove: (space, evt, keys) => this.handleActions(space, evt, keys, 'mousemove'),
        mouseup: (space, evt, keys) => this.handleActions(space, evt, keys, 'mouseup'),
      },
    });
    this.buildingController.startEvents();
    const spacesToWhiteList = [];
    this.currentFloors.forEach(floor => {
      spacesToWhiteList.push(...floor.whitelist);
    });
    const whitelistedSpaceElements = this.$palmier.spaces({ whitelist: spacesToWhiteList });
    whitelistedSpaceElements.apply({
      events: {
        mouseenter: (space, evt, keys) => this.spaceMouseenter(space, evt, keys),
        mouseout: (space, evt, keys) => this.spaceMouseout(space, evt, keys),
      },
    });

    const blackListedSpaceElements = this.$palmier.spaces({ blacklist: spacesToWhiteList });
    blackListedSpaceElements.spaces.forEach(space => {
      space.style.fill = 'url(#hash-pattern-private)';
    });
    const blackListedNamespaceElements = this.$palmier.nameSpaces({ blacklist: spacesToWhiteList });
    blackListedNamespaceElements.nameSpaces.forEach(nameSpace => {
      nameSpace.parentNode.removeChild(nameSpace);
    });
  },
  beforeDestroy() {
    if (this.buildingController) this.buildingController.removeEvents();
  },
  methods: {
    ...mapMutations('ui', {
      setDisplayMode: 'SET_DISPLAY_MODE',
      setViewerMode: 'SET_VIEWER_MODE',
      setSidebarInState: 'SET_SIDEBARIN_STATE',
      setSidebarOutState: 'SET_SIDEBAROUT_STATE',
    }),
    handleActions(space, evt, keys, eventName) {
      if (space) {
        const isSpaceWhitelisted = this.currentFloors.some(floor => floor.whitelist.find(whiteListSpace => whiteListSpace === space.id));
        if (eventName !== 'wheel' && eventName !== 'mouseup' && !isSpaceWhitelisted) return;
      }
      if (!this.actions[this.viewerMode][eventName]) return;
      this.actions[this.viewerMode][eventName](space, evt, keys);
    },
    handleViewerModeCreated(mode) {
      if ('created' in this.actions[mode]) this.actions[mode].created();
    },
    initZoom() {
      if (this.currentBuilding.metadata.preferences && this.currentBuilding.metadata.preferences.zoom)
        this.floor.zoom.min = this.currentBuilding.metadata.preferences.zoom;
      this.floor.zoom.value = this.floor.zoom.min;
    },
    setFloorLevel(floorIndex) {
      this.floor.zoom.value = this.floor.zoom.min;
      this.updateFloorStyle(this.currentFloorIndex, this.currentOrientationDegree, this.floor.zoom.value);
      this.$store.commit('building/setSelectedFloor', floorIndex);
    },
    preventWheel(evt) {
      const scrollable = evt.target.closest('.normal-scroll');
      /**
       * if scrollable element is not null it means that the wheel
       * event has beet triggered inside a "normal-scroll" element
       * therefore we don't prevent wheel event in this case
       * also prevent ctrlKey to avoid zoom in scrollable elt
       */
      if (!scrollable || evt.ctrlKey) {
        evt.preventDefault();
      }
    }
  },
};
</script>

<style lang="stylus">
@import '~variables'

.layout-page
  height 100vh

.grab-cursor
  cursor grab

.grabbing-cursor
  cursor grabbing

.building-viewer
  position relative
  overflow hidden
  width 100%
  height 100%
  outline none
  background-color #ddd9d3
  #buildingContainer
    height 100%
  .building-viewer__controls
    position absolute
    z-index 1000
    display flex
    flex-direction column
    &--left
      left 20px
    &--right
      top 15px
      right 20px
  .spinner-loader
    stroke black
    fill black
  .building-viewer__display-container
    display flex
    justify-content center
    align-items center
    height 100%
  .building-viewer__message
    text-align center
    font-size 1.4em
    .link
      color $dark
      &:hover
        color $grey
</style>
