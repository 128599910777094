export default {
  methods: {
    anim(floorIndex) {
      const constant = this.mode === 'iso' ? 0 : 700;
      const zIndex = 500;
      const spacing = 10;
      const viewer = this.viewer ? this.viewer.getBoundingClientRect() : null;
      this.currentFloors.forEach((f, idx) => {
        const floorNode = this.$palmier.getFloor(idx);
        const floorZIndex = zIndex - idx;
        let topPosition = 0;
        if (floorIndex === '*') {
          topPosition = -18 + spacing * idx;
        } else if (idx < floorIndex) {
          topPosition = (viewer.height / 1.7) * -1 + spacing * idx - constant;
        } else if (idx > floorIndex) {
          topPosition = viewer.height - floorNode.getBoundingClientRect().height / 1.5 + spacing * idx + constant;
        } else if (this.$q.platform.is.mobile) topPosition = this.navTabsOpened ? -170 : -50;
        floorNode.style.zIndex = floorZIndex;
        floorNode.style.top = `${topPosition}px`;
        floorNode.style.left = `0px`;
      });
    },
    resetFloorsPlacement(hideBeforeLoad) {
      this.$store.commit('building/setSelectedFloor', 0);
      if (!hideBeforeLoad) {
        this.floor.zoom.value = this.floor.zoom.min;
        this.anim('*');
        return;
      }
      this.readyToAnimate = false;
      this.readyToload = false;
      setTimeout(() => {
        this.readyToload = true;
        setTimeout(() => {
          this.readyToAnimate = true;
        }, Math.random() * 1000);
      }, 100);
    },
    dragNodeElement(node, user) {
      const xmouse1 = user.clientX;
      const ymouse1 = user.clientY;
      const vp = this.$refs.viewer.getBoundingClientRect();
      if (this.drag.lock === true) {
        const moveX = xmouse1 - vp.x - (this.xmouse - this.nodeX);
        const moveY = ymouse1 - vp.y - (this.ymouse - this.nodeY);
        node.style.top = `${moveY}px`;
        node.style.left = `${moveX}px`;
      }
    },
    updateFloors() {
      this.currentFloors.forEach((f, idx) => {
        if (idx === this.currentFloorIndex) this.updateFloorStyle(idx, this.currentOrientationDegree, this.floor.zoom.value);
        else this.updateFloorStyle(idx, this.currentOrientationDegree, this.floor.zoom.min);
      });
    },
    updateFloorStyle(floorId, orientation, zoom) {
      const floorNode = this.$palmier.getFloor(floorId);
      if (!floorNode) return;
      const floorSVG = floorNode.firstElementChild;
      if (!floorSVG) return;
      floorSVG.style.transformOrigin = 'center';
      let transform = '';
      if (this.mode === 'iso') transform = `scale(${zoom}) rotateX(-60deg) rotateY(0deg) rotateZ(${orientation}deg)`;
      else if (this.mode === 'flat') transform = `scale(${zoom}) rotateZ(${orientation - 45}deg)`;
      floorSVG.style.transform = transform;
      if (zoom > this.floor.zoom.min) floorNode.style.zIndex = 500 + floorId;
      const canvas = document.getElementById(`canvas${floorId}`);
      if (canvas) canvas.style.transform = transform;
    },
    animFloorChange(direction) {
      this.floor.zoom.value = this.floor.zoom.min;
      this.updateFloors();
      if (direction === 'up') this.$store.commit('building/setSelectedFloor', this.currentFloorIndex - 1);
      else if (direction === 'down') this.$store.commit('building/setSelectedFloor', this.currentFloorIndex + 1);
      this.anim(this.currentFloorIndex);
    },
    floorIsSelected(building, floorUuid) {
      if (!building) return false;
      return floorUuid in building;
    },
  },
};
