<template>
  <div @click.stop="">
    <div v-if="device.created && !editDevice"
         class="device-info">
      <h3 class="device-title">
        {{ device.uuid }}
      </h3>
      <div class="device-description">
        {{ device.description }}
      </div>
      <div class="device-actions">
        <div class="o-button o-button--shadow o-button--hover"
             @click="editDevice = true; $emit('edit')">
          {{$t('components.deviceInfo.editDevice')}}
        </div>
      </div>
    </div>
    <div v-if="!device.created || editDevice"
         class="device-add">
      <TextField v-model="fields.deviceUuid.value"
                 :label="$t('components.deviceInfo.labels.deviceUuid')"
                 :errorMessage="fields.deviceUuid.error"
                 type="text"
                 :required="fields.deviceUuid.required" />
      <Field :label="$t('components.deviceInfo.labels.type')"
             :required="fields.deviceType.required"
             :errorMessage="fields.deviceType.error">
        <QSelect v-model="fields.deviceType.value"
                 class="o-select"
                 :class="{'o-select--negative': fields.deviceType.error}"
                 :options="deviceTypes" />
      </Field>
      <Field class="type-select"
             :label="$t('components.deviceInfo.labels.description')"
             :required="fields.description.required">
        <textarea v-model="fields.description.value"
                  class="device-description o-input" />
        </Field>
      <div class="device-actions">
        <button class="o-button o-button--shadow o-button--hover"
             @click="device.created ? updateDevice() : saveDevice()">
             {{$t('components.deviceInfo.save')}}
        </button>
        <button v-if="device.created"
              class="o-button o-button--shadow o-button--hover"
             @click="moveDevice()">
             {{$t('components.deviceInfo.move')}}
        </button>
        <img class="trash"
             src="/assets/ui/PNG/trash.png"
             alt="trash"
             @click="removeDevice()">
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';

import Field from '@/app/components/inputs/field.vue';
import TextField from '@/app/components/inputs/text-field.vue';

import Device from '@/services/models/device';

export default {
  name: 'DeviceInfo',
  components: {
    Field,
    TextField,
  },
  props: {
    device: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editDevice: false,
      deviceTypes: [],
      fields: {
        deviceUuid: {
          label: 'Device UUID',
          value: '',
          error: '',
          required: true,
        },
        deviceType: {
          label: 'Type',
          value: '',
          error: '',
          required: true,
        },
        description: {
          label: 'Description',
          value: '',
          error: '',
          required: false,
        },
      },
    };
  },
  computed: {
    currentBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    currentSpace() {
      return this.$store.getters['building/selectedSpace'];
    },
  },
  watch: {
    device: {
      handler() {
        if (this.device && this.device.created) {
          this.fields.deviceUuid.value = this.device.uuid;
          this.fields.description.value = this.device.description;
        } else {
          this.fields.deviceUuid.value = '';
          this.fields.description.value = '';
        }
      },
      deep: true,
    },
  },
  async created() {
    const { data: deviceTypes } = await api.devices.deviceTypes.fetch();
    this.deviceTypes = deviceTypes.map(t => ({ value: t.id, label: t.name }));
  },
  mounted() {
    if (this.device && this.device.created) {
      this.fields.deviceUuid.value = this.device.uuid;
      this.fields.description.value = this.device.description;
    }
  },
  methods: {
    updateDevice() {
      if (this.deviceUuid === '') return;
      try {
        this.$emit('save');
        this.$q.notify({ message: `Device successfully updated`, type: 'positive', position: 'bottom-left' });
      } catch (error) {
        this.$q.notify({
          message: error.response.data || 'Error when updating device',
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
    async saveDevice() {
      if (!this.formIsValid()) return;
      try {
        const { data: rawData } = await api.devices.create({
          deviceTypeId: this.fields.deviceType.value,
          uuid: this.fields.deviceUuid.value,
          spaceUuid: this.currentSpace.uuid,
          description: this.fields.description.value,
          x: this.device.positionX,
          y: this.device.positionY,
        });
        const createdDevice = new Device({ ...rawData, idIFC: this.currentSpace.uuid });
        this.currentBuilding.devices.push(createdDevice);
        const deviceDot = document.getElementById(this.device.uuid);
        deviceDot.id = createdDevice.uuid;
        this.$emit('save');
        this.$q.notify({ message: `Device successfully added`, type: 'positive', position: 'bottom-left' });
      } catch (error) {
        this.$q.notify({
          message: error.response.data || 'Error when adding device',
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
    async removeDevice() {
      if (!this.device.created) {
        this.$emit('remove');
        return;
      }
      try {
        await api.devices.delete(this.device.uuid);
        const deviceIndex = this.currentBuilding.devices.findIndex(d => d.uuid === this.device.uuid);
        this.currentBuilding.devices.splice(deviceIndex, 1);
        this.$emit('remove');
        this.$q.notify({ message: `Device successfully removed`, type: 'positive', position: 'bottom-left' });
      } catch (error) {
        this.$q.notify({ message: error.response.data || `Error when removing device`, type: 'negative', position: 'bottom-left' });
      }
    },
    moveDevice() {
      const currentDevice = this.currentBuilding.devices.find(d => d.uuid === this.device.uuid);
      this.$emit('move', currentDevice);
    },
    formIsValid() {
      this.resetErrors();
      let formIsValid = true;
      Object.keys(this.fields).forEach(k => {
        if (!this.fields[k].required) return;
        if (this.fields[k].value === '') {
          this.fields[k].error = `${this.fields[k].label} can't be empty`;
          formIsValid = false;
        }
      });
      return formIsValid;
    },
    resetErrors() {
      Object.keys(this.fields).forEach(k => {
        this.fields[k].error = '';
      });
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.device-info, .device-add
  padding 16px
  cursor default
  .device-actions
    display flex
    align-items center
    .o-button
      width auto
      background-color black
      color white
      text-align center
      text-transform uppercase
      letter-spacing 0.5px
      font-weight 700
      font-size $fs-h3

.device-info
  .device-title
    margin 0
    margin-bottom 16px
    text-transform uppercase
    font-weight 700
    font-size $fs-h2
    line-height 1
    user-select text

.device-add
  width 300px
  .o-input
    width 100%
  .device-description
    height auto
    resize none
  .device-actions
    .o-button
      flex 2
    .trash
      margin-left 16px
      width 18px
      height 20px
      cursor pointer
</style>
