export default {
  methods: {
    onSwipeEvent(obj) {
      if (this.floor.zoom.value > this.floor.zoom.min) {
        const floor = document.querySelector(`#floor${this.currentFloorIndex}`);
        this.drag.move = true;
        this.drag.mousemoved = true;
        this.popup.show = false;
        this.dragNodeElement(floor, { clientX: obj.position.left, clientY: obj.position.top });
      } else {
        if (!obj.isFinal) return;
        this.drag.move = false;
        this.floor.zoom.value = this.floor.zoom.min;
        if (obj.direction === 'down') this.$store.commit('building/setSelectedFloor', this.currentFloorIndex - 1);
        else if (obj.direction === 'up') this.$store.commit('building/setSelectedFloor', this.currentFloorIndex + 1);
        this.anim(this.currentFloorIndex);
      }
    },
    onPinchEvent(obj) {
      if (obj.scale > this.floor.zoom.min) this.floor.zoom.value += 0.05;
      else this.floor.zoom.value -= 0.05;
      if (this.floor.zoom.value < this.floor.zoom.value + 0.5) this.floor.zoom.value = this.floor.zoom.min;
      if (this.floor.zoom.value === this.floor.zoom.min) {
        const node = document.getElementById(`floor${this.currentFloorIndex}`);
        if (node) {
          node.style.top = `0px`;
          node.style.left = `0px`;
        }
      }
    },
    onTouchStart(obj) {
      if (obj.touches && obj.touches.length) {
        const floor = document.querySelector(`#floor${this.currentFloorIndex}`);
        this.drag.lock = true;
        this.xmouse = obj.touches[0].clientX;
        this.ymouse = obj.touches[0].clientY;
        this.nodeX = floor.getBoundingClientRect().x ? floor.getBoundingClientRect().x : ' ';
        this.nodeY = floor.getBoundingClientRect().y;
      } else this.drag.lock = false;
    },
  },
};
