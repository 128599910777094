export default {
  data() {
    return {
      keyCode: {
        ctrl: 17,
      },
    };
  },
  methods: {
    navigationMode() {
      this.setSidebarInState(true);
      this.setSidebarOutState('small');
      this.floor.zoom.value = this.floor.zoom.min;
      this.showMapControls = true;
      this.setDisplayMode('iso');
      this.updateSpaceSelectionStyle();
      this.colorSpacesWithData(this.currentTimelineCursorDate);
    },
    // ---------- MOUSE EVENTS ----------
    navigationClick(space, evt, keys) {
      let showFilterPopup = null;
      if (space && (!this.popup.show || this.popup.error) && !this.drag.mousemoved) {
        if (this.currentSpaceSelection) this.currentSpaceSelection.classList.remove('selected');
        const floor = this.getFloorFromSpaceId(space.id);
        const currentSpace = floor.next.find(s => s.idIFC === space.id);
        const selection = {
          building: this.currentBuilding.uuid,
          floor: floor.uuid,
          space: {
            idIFC: currentSpace.idIFC,
            uuid: currentSpace.uuid,
            properties: currentSpace.properties,
          },
        };
        const spaceInFilters = this.selectInFilters(selection);
        const floorInFilters = this.selectInFilters({ building: this.currentBuilding.uuid, floor: floor.uuid });

        if (keys.Control) {
          // multiple selection
          if (this.spaceIsSelected(currentSpace.idIFC)) {
            this.$store.commit('selections/unselectBuilding', selection);
            this.$store.commit('building/setSelectedSpace', null);
          } else if (!spaceInFilters || spaceInFilters.find(s => s.uuid === currentSpace.uuid)) {
            this.$store.commit('selections/selectBuilding', selection);
            this.$store.commit('building/setSelectedSpace', space);
          } else {
            showFilterPopup = space.id;
          }
        } else {
          // simple selection
          if (!spaceInFilters || spaceInFilters.find(s => s.uuid === currentSpace.uuid)) {
            this.$store.commit('selections/unselectBuilding', { building: this.currentBuilding.uuid });
            this.$store.commit('selections/selectBuilding', { building: this.currentBuilding.uuid });
            if (!this.currentSpaceSelection || this.currentSpaceSelection.id !== space.id) {
              this.$store.commit('selections/selectBuilding', selection);
              this.$store.commit('building/setSelectedSpace', null);
            } else {
              if (floorInFilters && floorInFilters.length !== floor.next.length) {
                floorInFilters.forEach(s => {
                  this.$store.commit('selections/selectBuilding', { building: this.currentBuilding.uuid, floor: floor.uuid, space: s });
                });
              }
              this.$store.commit('building/setSelectedSpace', null);
            }
          } else {
            showFilterPopup = space.id;
          }
        }
        this.$store.dispatch('oapp/fetchCurrentOappData');
      } else if (
        !space &&
        (!this.popup.show || this.popup.error) &&
        !this.drag.mousemoved &&
        this.currentBuilding &&
        Object.keys(this.filteredSelection).length &&
        !keys.Control
      ) {
        const floor = this.currentFloors[this.currentFloorIndex];
        const floorInFilters = this.selectInFilters({ building: this.currentBuilding.uuid, floor: floor.uuid });
        const currentBuildingSelected = this.filteredSelection[this.currentBuilding.uuid];
        if (floor && this.floorIsSelected(currentBuildingSelected, floor.uuid) && currentBuildingSelected[floor.uuid].length > 0) {
          this.$store.commit('selections/unselectBuilding', {
            building: this.currentBuilding.uuid,
            floor: floor.uuid,
          });
          if (floorInFilters && floorInFilters.length !== floor.next.length) {
            floorInFilters.forEach(s => {
              this.$store.commit('selections/selectBuilding', { building: this.currentBuilding.uuid, floor: floor.uuid, space: s });
            });
          }
          this.$store.commit('building/setSelectedSpace', null);
          this.$store.dispatch('oapp/fetchCurrentOappData');
        }
      }
      this.drag.mousemoved = false;
      this.popup.show = false;
      this.popup.error = null;
      if (showFilterPopup) {
        this.showPopup(evt.clientX, evt.clientY);
        this.$store.commit('building/setSelectedSpace', showFilterPopup);
        this.popup.error = `Space doesn't match filters`;
      }
    },
    navigationRightClick(space, e, keys) {
      this.popup.error = null;
      if (!['navigation', 'zoneEdition', 'propertiesEdition'].includes(this.viewerMode)) this.popup.show = false;
      else {
        this.popup.move = true;
        space = this.$palmier.getSpace(e.target.id);
        if (space) {
          const isSpaceWhitelisted = this.currentFloors.some(floor => floor.whitelist.find(whiteListSpace => whiteListSpace === space.id));
          if (!isSpaceWhitelisted) return;
          this.showPopup(e.clientX, e.clientY);
          this.$store.commit('building/setSelectedSpace', space);
        } else {
          this.popup.move = false;
          this.popup.show = false;
        }
      }
    },
    navigationWheel(space, evt, key) {
      this.popup.show = false;
      this.popup.error = null;
      const scrollUp = evt.deltaY < 0 ? true : false;
      if (evt.ctrlKey) {
        if (scrollUp) this.floor.zoom.value += this.floor.zoom.step;
        else this.floor.zoom.value -= this.floor.zoom.step;
      } else {
        if (scrollUp) this.animFloorChange('up');
        else this.animFloorChange('down');
      }
    },
    navigationMouseout(space, evt, keys) {
      this.popup.move = false;
    },
    navigationMouseMove(space, evt, keys) {
      this.drag.ctrl = keys.Control;
      if (this.drag.lock) {
        // drag the floor
        const floor = this.$palmier.getFloor(this.currentFloorIndex);
        this.drag.move = true;
        this.drag.mousemoved = true;
        this.popup.show = false;
        this.dragNodeElement(floor, evt);
      } else this.drag.move = false;
    },
    navigationMouseUp(space, evt, keys) {
      this.drag.lock = false;
      this.drag.move = false;
    },
    navigationLeftMouseDown(space, e, keys) {
      if (e.ctrlKey) {
        const floor = document.querySelector(`#floor${this.currentFloorIndex}`);
        this.drag.lock = true;
        this.xmouse = e.clientX;
        this.ymouse = e.clientY;
        this.nodeX = floor.getBoundingClientRect().x ? floor.getBoundingClientRect().x : ' ';
        this.nodeY = floor.getBoundingClientRect().y;
      } else this.drag.lock = false;
    },
    // ---------- KEYBOARD EVENTS ----------
    navigationKeyUp(space, evt, key) {
      if (evt.keyCode === this.keyCode.ctrl) this.drag.ctrl = false;
    },
    navigationPageUp(space, evt, key) {
      this.popup.show = false;
      this.animFloorChange('down');
    },
    navigationPageDown(space, evt, key) {
      this.popup.show = false;
      this.animFloorChange('up');
    },
    navigationCtrlArrows(space, evt, key) {
      this.popup.show = false;
      const floor = document.querySelector(`#floor${this.currentFloorIndex}`);
      const move = key === 'down' || key === 'right' ? 50 : -50;
      if (key === 'up' || key === 'down') floor.style.top = `${parseInt(floor.style.top) + move}px`;
      else floor.style.left = `${parseInt(floor.style.left) + move}px`;
    },
    navigationCtrlPlus(space, evt, key) {
      this.floor.zoom.value += this.floor.zoom.step;
    },
    navigationCtrlMinus(space, evt, key) {
      this.floor.zoom.value -= this.floor.zoom.step;
    },
    navigationCtrlDown(space, evt, key) {
      this.drag.ctrl = true;
    },
  },
};
