import api from 'api';

export default {
  methods: {
    deviceEditionMode() {
      this.setSidebarInState(false);
      this.setSidebarOutState('hidden');
      this.$store.commit('oapp/setCurrentOapp', undefined);
      this.$store.dispatch('oapp/fetchCurrentOappData');
      this.popup.show = false;
      this.showMapControls = false;
      this.setDisplayMode('flat');
      this.resetSpacesStyle();
      const space = this.$palmier.getSpace(this.currentSpace.idIFC);
      space.style.fill = this.colors.light;
      const floor = this.$palmier.getFloor(this.currentFloorIndex);
      // center space to edit
      // set 200ms timeout to wait for animation to finish
      // Why 200ms ? because the SVG transition on transform is 200ms => floor component
      setTimeout(() => this.centerSpace(space, floor), 200);
    },
    // mouse move
    editionMousemove(space, evt, keys) {
      space = this.$palmier.getSpace(evt.target.id);
      if (this.isSpaceToEdit(this.currentSpace, space)) {
        this.buildingController.building.classList.remove('cursor--close-device');
        this.buildingController.building.classList.add('cursor--add-device');
      } else if (this.isDevice(evt)) {
        this.buildingController.building.classList.remove('cursor--close-device');
        this.buildingController.building.classList.remove('cursor--add-device');
      } else {
        this.buildingController.building.classList.remove('cursor--add-device');
        this.buildingController.building.classList.add('cursor--close-device');
      }
      if (this.drag.lock) {
        const floor = this.$palmier.getFloor(this.currentFloorIndex);
        this.drag.move = true;
        this.drag.mousemoved = true;
        this.popup.show = false;
        if (this.currentDevice && this.currentDevice.uuid) this.removeDeviceDot(this.currentDevice.uuid);
        this.dragNodeElement(floor, evt);
      } else this.drag.move = false;
    },
    // click
    async editionClick(space, evt, keys) {
      space = this.$palmier.getSpace(evt.target.id);
      if (this.drag.mousemoved || this.drag.move) {
        this.drag.mousemoved = false;
        return;
      }
      if (this.isDevice(evt)) {
        // click on device
        if (!this.currentDevice.created && this.currentDevice.uuid) this.removeDeviceDot(this.currentDevice.uuid);
        const device = document.getElementById(evt.target.id);
        this.setCurrentDevice({
          uuid: evt.target.id,
          positionX: device.__vue__.x,
          positionY: device.__vue__.y,
          created: true,
        });
        this.showPopup(evt.clientX, evt.clientY);
      } else if (this.isSpaceToEdit(this.currentSpace, space)) {
        // click on space to add device
        // when moving a device
        if (this.movingDevice) {
          const path = evt.path || (evt.composedPath && evt.composedPath());
          const floorSVG = path[1];
          const device = this.createDeviceDot(this.currentDevice.uuid, evt.clientX, evt.clientY, floorSVG);
          floorSVG.insertBefore(device.$el, space.nextSibling);
          await api.devices.update(this.currentDevice.plug, parseInt(device.x), parseInt(device.y));
          this.movingDevice = false;
        } else {
          // when creating a new device
          if (!this.currentDevice.created && this.currentDevice.uuid) this.removeDeviceDot(this.currentDevice.uuid);
          this.setCurrentDevice({ uuid: null });
          const path = evt.path || (evt.composedPath && evt.composedPath());
          const floorSVG = path[1];
          const tmpUuid = this.generateRandomUUID();
          const device = this.createDeviceDot(tmpUuid, evt.clientX, evt.clientY, floorSVG);
          floorSVG.insertBefore(device.$el, space.nextSibling);
          this.setCurrentDevice({
            uuid: tmpUuid,
            positionX: device.x,
            positionY: device.y,
          });
          this.showPopup(evt.clientX, evt.clientY);
        }
      } else {
        if (this.movingDevice) {
          const path = evt.path || (evt.composedPath && evt.composedPath());
          const floorSVG = path[0];
          const device = this.createDeviceDot(this.currentDevice.uuid, this.currentDevice.positionX, this.currentDevice.positionY);
          const space = this.$palmier.getSpace(this.currentDevice.space);
          floorSVG.insertBefore(device.$el, space.nextSibling);
          this.movingDevice = false;
        }
        // click out of space
        if (!this.currentDevice.created && this.currentDevice.uuid) this.removeDeviceDot(this.currentDevice.uuid);
        this.buildingController.building.classList.remove('cursor--close-device');
        this.buildingController.building.classList.remove('cursor--add-device');
        this.setCurrentDevice({ uuid: null });
        this.setViewerMode('navigation');
        this.updateDeviceDotSelection();
      }
    },
    editionWheel(space, evt, key) {
      if (!evt.ctrlKey) return;
      if (this.currentDevice && this.currentDevice.uuid && this.floor.zoom.value > this.floor.zoom.min) {
        this.removeDeviceDot(this.currentDevice.uuid);
        this.popup.show = false;
      }
      const scrollUp = evt.deltaY < 0 ? true : false;
      if (scrollUp) this.floor.zoom.value += this.floor.zoom.step;
      else this.floor.zoom.value -= this.floor.zoom.step;
    },
  },
};
