<template>
  <div class="space-info">
    <div class="header">
      <div class="header-main grid-wrapper grid-wrapper--top">
        <h3 v-if="!editName || !user.featuresRights.admin.WRITE"
            class="space-name"
            @click="editName = true"
            >
          {{ space.name }}
        </h3>
        <div v-else>
          <!-- imput to update space name, accessible only to admin user -->
          <input v-model="space.name"
                name="name"
                @keyup.enter="updateName"
                />
          <q-btn icon='fas fa-check'
                size="sm"
                @click="updateName"/>
        </div>
        <span v-if="!error && space && space.metadata"
              class="space-surface">
          {{ `${space.metadata && space.metadata.surface ? space.metadata.surface.toFixed(0) : 0} m` }}
          <sup>2</sup>
        </span>
      </div>
    </div>
    <div v-if="devicesControlRights.READ && spaceDevices.length"
         class="grid-wrapper">
    <div class="device-list">
         <label class="grid-tile__label">Equipements</label>
      <ListSpaceDevices :spaceDevices="spaceDevices" />
    </div>
    </div>
    <div class="zones-types-container grid-wrapper">
      <div v-if="spaceZoneName"
           class="zone-tile grid-tile">
        <label class="grid-tile__label">lots</label>
        <div class="grid-wrapper grid-wrapper--top">
          <div :style="zoneNameStyle"
               class="zone-name"
               @mouseover="showZone"
               @mouseout="hideZone">
            <span :style="zoneTextStyle"
                  class="zone-name--text">
              {{ spaceZoneName }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="tags.length"
           class="type-tile grid-tile">
        <label class="grid-tile__label">
          types
        </label>
        <div class="grid-wrapper grid-wrapper--top">
          <div class="space-tags">
            <div v-for="t in tags"
                 :key="t"
                 :style="'border-color: '+t.color"
                 class="tag">
              {{ ['public', 'private'].includes(t.value) ? $t(`pages.buildingConfiguration.properties.access.${t.value}`) : $t(`pages.buildingConfiguration.properties.type.${t.value}`) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dataInfo"
         class="grid-wrapper">
      <div v-for="(value, profile) in translatedInfo"
           :key="profile"
           class="data-tile grid-tile">
        <label class="grid-tile__label">
          {{ $t(`oapps.data.${formatProfile(profile)}`) }}
        </label>
        <span class="grid-tile__value">
          {{ formatValue(value, profile) }}
        </span>
      </div>
    </div>
    <div v-if="viewerMode === 'navigation' && user.featuresRights.device.WRITE"
         class="space-info__content grid-wrapper">
      <button v-if="!error"
              class="add-device o-button o-button--shadows o-button--hover o-button--squared o-button--bordered"
              @click.stop="setEditionMode">
        {{$t('components.spaceInfo.editDevice')}}
      </button>
      <div v-else
           class="space-info__message">
        <q-icon name="warning"
                size="1.4rem"
                class="q-mr-sm" />
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import chroma from 'chroma-js';
import ListSpaceDevices from '@/app/components/spaces/list-space-devices.vue';
import store from '../../../services/store';
import api from 'api';

import UtilsMixin from '@/app/mixins/utils';

export default {
  name: 'SpaceInfo',
  components: {
    ListSpaceDevices
  },
  mixins: [UtilsMixin],
  props: {
    space: {
      type: Object,
      default: () => ({ name: 'no name' }),
    },
    dataInfo: {
      type: Object,
      default: null,
    },
    spaceDevices: {
      type: Object,
      default: null
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      units: {
        temperature: '°C',
        cov: '%',
        humidity: '%',
        co2: 'ppm',
        sound: 'dB',
        parking: '%',
      },
      oldColors: {},
      oldColorsUserSpaces: {},
      editName: false,
    };
  },
  computed: {
    devicesControlRights() {
      return store.getters['user/featuresRights'].devicesControl;
    },
    ...mapState('ui', ['viewerMode']),
    propertiesColors() {
      return this.$store.state.zones.properties.colors;
    },
    selectedBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    userNodeOfSpace() {
      return this.selectedBuilding.users.filter(userNode => userNode.spaces.find(userNodeSpace => userNodeSpace.uuid === this.space.uuid));
    },
    tags() {
      const list = [];
      if (this.space.properties) {
        if (this.space.properties.access)
          list.push({
            value: this.space.properties.access,
            color: this.propertiesColors.access[this.space.properties.access] || '#F3F1EF',
          });
        if (this.space.properties.type)
          list.push({ value: this.space.properties.type, color: this.propertiesColors.type[this.space.properties.type] || '#F3F1EF' });
      }
      return list;
    },
    zoneNameStyle() {
      const zone = this.$store.getters['zones/getZoneBySpaceUuid'](this.space.idIFC);
      if (!zone || !zone.color) return '';
      const color = chroma(zone.color)
        .luminance(0.7)
        .hex();
      return `background-color: ${color}`;
    },
    zoneTextStyle() {
      const zone = this.$store.getters['zones/getZoneBySpaceUuid'](this.space.idIFC);
      if (!zone) return '';
      if (zone.name.length > 23) return `background: linear-gradient(to right, black 80%, transparent); color: transparent;`;
      return '';
    },
    spaceZoneName() {
      const zone = this.$store.getters['zones/getZoneBySpaceUuid'](this.space.idIFC);
      if (zone) return zone.name.slice(0, 23);
      return '';
    },
    user() {
      return this.$store.state.user.user;
    },
    translatedInfo() {
      if (this.dataInfo)
        return Object.keys(this.dataInfo).reduce((acc, k) => {
          if (this.formatProfile(k) === 'presence') acc[k] = this.$t(`components.spaceInfo.presence.${this.dataInfo[k]}`);
          else acc[k] = this.dataInfo[k];
          return acc;
        }, {});
      else return {};
    },
  },
  methods: {
    async updateName() {
      await api.topology.patchNode(this.space.uuid, { name: this.space.name });
      this.editName = false;
      this.updateSVGSpaceName(this.space);
    },
    updateSVGSpaceName(space) {
      const spaceNamesElts = document.querySelectorAll('text[class=space-name]');
      Object.values(spaceNamesElts).forEach(spaceNameElt => {
        if (spaceNameElt.id === space.uuid) {
          spaceNameElt.innerHTML = space.name;
        }
      });
    },
    ...mapMutations('ui', {
      setViewerMode: 'SET_VIEWER_MODE',
    }),
    setEditionMode() {
      this.setViewerMode('deviceEdition');
    },
    formatProfile(profile) {
      return profile.split('.')[0];
    },
    formatValue(value, profile) {
      return this.formatData(value, 1, this.units[this.formatProfile(profile)]);
    },
    showZone() {
      const zone = this.$store.getters['zones/getZoneBySpaceUuid'](this.space.idIFC);
      zone.spaces.forEach(s => {
        const space = this.$palmier.getSpace(s);
        if (space) {
          this.oldColors[s] = space.style.fill;
          space.style.fill = zone.color;
        }
      });
    },
    hideZone() {
      const zone = this.$store.getters['zones/getZoneBySpaceUuid'](this.space.idIFC);
      zone.spaces.forEach(s => {
        const space = this.$palmier.getSpace(s);
        if (space && s in this.oldColors) space.style.fill = this.oldColors[s];
        delete this.oldColors[s];
      });
    },
    showSpacesOfUser(userUUID) {
      const userNodesSpaces = this.selectedBuilding.users.find(userNode => userNode.user.uuid === userUUID);
      userNodesSpaces.spaces.forEach(s => {
        const space = this.$palmier.getSpace(s.idIFC);
        if (space) {
          this.oldColorsUserSpaces[s.idIFC] = space.style.fill;
          space.style.fill = 'red';
        }
      });
    },
    hideSpacesOfUser(userUUID) {
      const userNodesSpaces = this.selectedBuilding.users.find(userNode => userNode.user.uuid === userUUID);
      userNodesSpaces.spaces.forEach(s => {
        const space = this.$palmier.getSpace(s.idIFC);
        if (space && s.idIFC in this.oldColorsUserSpaces) space.style.fill = this.oldColorsUserSpaces[s.idIFC];
        delete this.oldColorsUserSpaces[s.idIFC];
      });
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.space-info
  display flex
  flex-direction column
  min-width 200px
  .header
    .header-main
      display flex
      padding 20px
      .space-name
        margin 0
        margin-right 16px
        text-transform uppercase
        font-weight 700
        font-size $fs-h1
        line-height 1
      .space-surface
        font-weight 100
  .device-list
    padding 10px 20px 3px 20px
    .devices
      display flex
      flex-direction row
      flex-wrap wrap
      max-height 120px
      max-width 155px
      overflow-y auto
  .zones-types-container
    display flex
  .zone-name
    display inline-block
    padding 1px 6px
    border-radius 20px
    text-transform uppercase
    font-size 0.9rem
    .zone-name--text
      background-clip text
      -webkit-background-clip text !important
  .space-data-list
    display flex
    flex-wrap wrap
    margin-bottom 8px
    padding-bottom 4px
    border-bottom $border-light solid $silver
    .space-data
      margin-right 4px
      margin-bottom 4px
      padding-right 8px
      padding-left 8px
      max-width 350px
      width fit-content
      border 1px solid darken($light, 10%)
      &__profile
        margin-right 4px
        font-weight 100
        font-size $fs-h2
      &__value
        font-weight 400
        font-size $fs-h2
  .space-info__content
    justify-content center
    padding 20px
    .add-device
      width fit-content
      background-color white
      text-transform uppercase
    .space-info__message
      font-weight 700
  .space-tags
    max-width 350px
    word-break break-all
    .tag
      display inline-block
      margin-right 2px
      padding 1px 4px
      border solid 2px $light
      border-radius 20px
      background-color white
      color $dark
  .user-nodes
    margin-bottom 8px
    padding-bottom 8px
    max-width 350px
    border-bottom $border-light solid $silver
    word-break break-all
    .user-node
      margin-top 2px
      margin-right 2px
      padding 1px 4px
      border solid 2px $light
      border-radius 20px
      background-color white
      color $dark
  .data-tile
    padding $space-1 20px
    .grid-tile__value
      font-weight 700
      font-size $fs-h2

.grid-tile__label
  margin-bottom $space-little

.grid-tile
  border-right none
  border-left $grid-border

.zone-tile, .type-tile
  padding $space-1 20px
</style>
